/*
Author:      Dimitra Weinstein
Created:     12/20/2022
Modified:    12/30/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_DEFAULT_ZOOM,
  GOOGLE_MAPS_HOVER_DISTANCE,
  GOOGLE_MAPS_DEFAULT_LAT,
  GOOGLE_MAPS_DEFAULT_LNG
} from "../../utilities/constantsPulseMap";
import deepCopy from "../../utilities/deepCopy";
import calculateCenterZoom from "../../utilities/calculateCenterZoom";
import PulseMapMarker from "./PulseMapMarker/PulseMapMarker";
import PulseCountTable from "../../pages/PulseCountTable/PulseCountTable";
import styles from "./Map.module.scss";

export default function Map(props) {
  const [center, setCenter] = useState({
    lat: GOOGLE_MAPS_DEFAULT_LAT,
    lng: GOOGLE_MAPS_DEFAULT_LNG
  });
  const [zoom, setZoom] = useState(GOOGLE_MAPS_DEFAULT_ZOOM);
  const [pulses, setPulses] = useState([]);
  const [points, setPoints] = useState([]);


  useEffect(() => {
    const tempPulseDevices = [];
    const tempPoints = [];
    props.pulseDevices.forEach((pulse) => {
      if (
        pulse.deviceIdentifier !== undefined &&
        pulse.latitude !== undefined &&
        pulse.longitude !== undefined &&
        pulse.requestTimeUtc !== undefined &&
        pulse.temperature !== undefined &&
        pulse.velocity !== undefined
      ) {
        tempPulseDevices.push(deepCopy(pulse));
        tempPoints.push({
          latitude: pulse.latitude,
          longitude: pulse.longitude
        });
      }
    });

    // Get dimensions of current map window in pixels.
    const mapElement = document.getElementById("google-maps-container");
    if (mapElement !== null) {
      const mapWidth = mapElement.width;
      const mapHeight = mapElement.height;

      // Find the new bound box that fits all markers.
      const newBounds = calculateCenterZoom(points, mapWidth, mapHeight);

      setCenter(newBounds.center);
      setZoom(newBounds.zoom - 1);
      setPulses(tempPulseDevices);
      setPoints(tempPoints);
    }
  }, [JSON.stringify(props.pulseDevices)]);


  return (
    <div>
      <div className={styles.mapWrapper} id="google-maps-container">
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          center={center}
          zoom={zoom}
          hoverDistance={GOOGLE_MAPS_HOVER_DISTANCE}
          GoogleApiLoaded
          options={{
            fullscreenControl: false,
            zoomControl: true,
            scaleControl: true,
            mapTypeControl: true,
            tilt: 0,
            gestureHandling: "auto"
          }}
          yesIWantToUseGoogleMapApiInternals
        >
          {pulses.map((pulse) =>
            pulse.deviceIdentifier !== undefined &&
            pulse.latitude !== undefined &&
            pulse.longitude !== undefined &&
            pulse.requestTimeUtc !== undefined &&
            pulse.temperature !== undefined &&
            pulse.velocity !== undefined &&
            (
              <PulseMapMarker
                key={pulse.deviceIdentifier}
                deviceIdentifier={pulse.deviceIdentifier}
                lat={pulse.latitude}
                lng={pulse.longitude}
                requestTimeUtc={pulse.requestTimeUtc}
                temperature={pulse.temperature}
                velocity={pulse.velocity}
                emailAddress={pulse.emailAddress}
              />
            )
          )}
          <PulseCountTable
            pulseCount={props.pulseCount}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
}

Map.propTypes = {
  pulseDevices: PropTypes.array.isRequired,
  pulseCount: PropTypes.string.isRequired
};


