/*
Author:      Dimitra Weinstein
Created:     12/20/2022
Modified:    12/27/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Error404Page from "../../pages/Error404Page/Error404Page";
import PulseMapPage from "../../pages/PulseMapPage/PulseMapPage";
import { SUBDIRECTORY } from "../../utilities/constantsPulseMap";

// Handles page routing for Pusle Map Dashboard.
export default function PulseMapRouter() {

  return (
    <Switch>
      <Fragment>
        <Route exact path={`/${SUBDIRECTORY}/`}>
          <Redirect to={`/${SUBDIRECTORY}`} />
        </Route>

        <Route exact path={`/${SUBDIRECTORY}`}>
          <PulseMapPage />
        </Route>
      </Fragment>

      <Route path="*">
        <Error404Page />
      </Route>
    </Switch>
  );
}


