/*
Author:      KC Willard
Created:     11/15/2022
Modified:    11/30/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CornellToolsAdministrativeConsolePage from "../../pages/CornellToolsAdministrativeConsolePage/CornellToolsAdministrativeConsolePage";
import Error404Page from "../../pages/Error404Page/Error404Page";
import {
  SUBDIRECTORY,
} from "../../utilities/constantsCornellToolsAdministrativeConsole";

// Handles page routing for Cornell Tools Admin Dashboard.
export default function CornellToolsAdministrativeConsoleRouter() {

  return (
    <Switch>
      <Fragment>
        <Route exact path={`/${SUBDIRECTORY}/`}>
          <Redirect to={`/${SUBDIRECTORY}`} />
        </Route>

        <Route exact path={`/${SUBDIRECTORY}`}>
          <CornellToolsAdministrativeConsolePage />
        </Route>
      </Fragment>

      <Route path="*">
        <Error404Page />
      </Route>
    </Switch>
  );
}


