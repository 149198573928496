/*
Author:      KC Willard
Created:     4/22/2022
Modified:    12/15/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/
import React from "react";
import PropTypes from "prop-types";
import { ROLES, APPLICATIONS, CONFIGURATOR, LEADTIME } from "../../../../utilities/constantsCornellToolsAdministrativeConsole";
import "./UserForm.scss";

// Form for creating and editing a user.
export default function CornellToolsUserForm(props) {

  return (
    props.mode === "edit" ? (
      <div className="user-form">
        <div className="row align-items-center justify-content-center">
          <div className="col-5 mb-3 text-center">
            <label >
              Email
            </label>
            <input
              className="form-control mx-auto"
              type="text"
              value={props.email}
              onChange={(e) => props.onChangeEmail(e.target.value)}
            />
          </div>

          <div className="col-4 mb-3 text-center">
            <label>
              Application
            </label>
            <select
              className="form-select dropdown"
              defaultValue={props.isConfigurator ? CONFIGURATOR : LEADTIME}
              onChange={(event) => props.onChangeApplication(event.target.value)}
            >
              {APPLICATIONS.map((application, i) =>
                <option
                  className="dropdown-item"
                  value={application.name}
                  key={i}
                >
                  {application.code}
                </option>
              )}
            </select>
          </div>

          <div className="col-3 mb-3 text-center">
            <label>
              Role
            </label>
            <select
              className="form-select dropdown"
              value={props.role}
              onChange={(event) => props.onChangeRole(event.target.value)}
            >
              {ROLES.map((role, i) =>
                <option
                  className="dropdown-item"
                  value={role.code}
                  key={i}
                >
                  {role.code}
                </option>
              )}
            </select>
          </div>
        </div>
      </div>
    ) : (
      <div className="user-form">
        <div className="row align-items-center justify-content-center">
          <div className="col-5 mb-3 text-center">
            <label >
              Email
            </label>
            <input
              className="form-control mx-auto"
              type="text"
              value={props.email}
              onChange={(e) => props.onChangeEmail(e.target.value)}
            />
          </div>

          <div className="col-4 mb-3 text-center">
            <label>
              Application
            </label>
            <select
              className="form-select dropdown"
              value={props.application}
              onChange={(e) => props.onChangeApplication(e.target.value)}
            >
              {APPLICATIONS.map((application, i) =>
                <option
                  className="dropdown-item"
                  value={application.name}
                  key={i}
                >
                  {application.code}
                </option>
              )}
            </select>
          </div>

          <div className="col-3 mb-3 text-center">
            <label>
              Role
            </label>
            <select
              className="form-select dropdown"
              value={props.role}
              onChange={(event) => props.onChangeRole(event.target.value)}
            >
              {ROLES.map((role, i) =>
                <option
                  className="dropdown-item"
                  value={role.code}
                  key={i}
                >
                  {role.code}
                </option>
              )}
            </select>
          </div>
        </div>
      </div>
    )
  );
}

CornellToolsUserForm.propTypes = {
  mode: PropTypes.string.isRequired,
  email: PropTypes.string,
  role: PropTypes.string,
  application: PropTypes.string,
  isConfigurator: PropTypes.bool.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  onChangeApplication: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired
};