/*
Author:      Dimitra Weinstein
Created:     12/20/2022
Modified:    12/30/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

const pulseMapSubdirectory = "pulse-map";
const googleMapsApiKey = "AIzaSyDex8EqZOH2aYYumdgvc6139kI5es5s0nI";

// Constants that are referenced throughout the Console.
module.exports = Object.freeze({
  DEV_API: "https://3xwjylh600.execute-api.us-west-2.amazonaws.com/dev",
  PROD_API: "https://3xwjylh600.execute-api.us-west-2.amazonaws.com/dev",
  SUBDIRECTORY: pulseMapSubdirectory,
  LINKS: [
    { name: "Manage Pulse Readings", url: `/${pulseMapSubdirectory}` }
  ],
  GOOGLE_MAPS_API_KEY: googleMapsApiKey,
  GOOGLE_MAPS_DEFAULT_ZOOM: 2,
  GOOGLE_MAPS_DEFAULT_LAT: 39.5,
  GOOGLE_MAPS_DEFAULT_LNG: -98.35,
  GOOGLE_MAPS_HOVER_DISTANCE: 30,
  ADMIN_ROLE: "ADMIN",
  OPERATIONS_ROLE: "OPERATIONS",
  BASIC_USER_ROLE: "USER",
  MIN_LATITUDE: -90,
  MAX_LATITUDE: 90,
  MIN_LONGITUDE: -180,
  MAX_LONGITUDE: 180
});

