/*
Author:      Zachary Thomas
Created:     4/6/2022
Modified:    11/15/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState, useContext } from "react";
import useApi from "../../hooks/useApi";
import apiRequest from "../../utilities/apiRequest";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import PageTitle from "../../components/PageTitle/PageTitle";
import AudienceSelect from "./AudienceSelect/AudienceSelect";
import SubsystemList from "./SubsystemList/SubsystemList";
import Context from "../../components/Context/Context";
import Card from "../../components/Card/Card";
import { LINKS, PROD_API, DEV_API } from "../../utilities/constantsNotificationManager";
import "./NotificationManagerSubsystemsPage.scss";
import Error from "../../components/Error/Error";
import Success from "../../components/Success/Success";
import { ADMIN_ROLE } from "../../utilities/constants";


// Page for viewing the list of subsystems.
export default function NotificationManagerSubsystemsPage() {
  const [loading, setLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [audienceType, setAudienceType] = useState("");
  const [endUserSubsystems, setEndUserSubsystems] = useState([]);
  const [operationSubsystems, setOperationSubsystems] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState([]);
  const context = useContext(Context);

  // Setting the title and the links to be displayed in the navbar for the current page.
  useEffect(() => {
    context.setTitle("Notification Manager");
    context.setLinks(LINKS);
  }, []);

  // Get all subsystems from API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/subsystem`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        setEndUserSubsystems(responseBody.rpmCustomerSubsystems);
        setOperationSubsystems(responseBody.operationSubsystems);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [context.isProd]
  );

  async function backupAlertMetaData() {
    setLoading(true);
    const [response, responseBody] = await apiRequest(
      `${context.isProd ? PROD_API : DEV_API}/metadata/backup`,
      "POST",
      null
    );
    setLoading(false);
    if (response.ok && responseBody) {
      setErrorMessage("");
      setSucessMessage("Backup was successful");
    } else {
      setErrorMessage("There was an error backing up metadata");
      setSucessMessage("");
    }
  }

  async function restoreAlertMetaData() {
    setLoading(true);
    const [response, responseBody] = await apiRequest(
      `${context.isProd ? PROD_API : DEV_API}/metadata/restore`,
      "POST",
      null
    );
    setLoading(false);
    if (response.ok && responseBody) {
      setErrorMessage("");
      setSucessMessage("Restore was successful");
    } else {
      setErrorMessage("There was an error restoring metadata");
      setSucessMessage("");
    }
  }

  return (
    failedToLoad ? (
      <Error500Page />
    ) : (
      <div className="page-note-subsystems mb-5">
        <Spinner loading={loading} />

        <PageTitle title="Manage Subsystems" />
        {[ADMIN_ROLE].includes(context.role) && (
          <div className="my-5">
            <Card title="Notification Metadata">
              <div className="row py-2">
                <div className="col">
                  <button className="delete-btn btn btn-success m-auto d-flex"
                    onClick={() => backupAlertMetaData()}
                  >Backup Metadata</button>
                </div>
                <div className="col">
                  <button
                    className="delete-btn btn btn-danger m-auto d-flex"
                    onClick={() => restoreAlertMetaData()}
                  >Restore Metadata</button>
                </div>
              </div>
              <Error message={errorMessage} />
              <Success message={successMessage} />
            </Card>
          </div>
        )}
        <div>
          <AudienceSelect
            audienceType={audienceType}
            onChange={(audienceType) => setAudienceType(audienceType)}
          />

          {audienceType.length > 0 && (
            <SubsystemList
              loading={false}
              audienceType={audienceType}
              subsystems={audienceType === "operations" ? operationSubsystems : endUserSubsystems}
            />
          )}
        </div>
      </div>
    )
  );
}

