/*
Author:      KC Willard
Created:     4/22/2022
Modified:    11/29/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import apiRequest from "../../../utilities/apiRequest";
import { PROD_API, DEV_API } from "../../../utilities/constantsCornellToolsAdministrativeConsole";
import Context from "../../../components/Context/Context";
import Spinner from "../../../components/Spinner/Spinner";
import "./CornellToolsUserList.scss";

// A list of Users that support creation, updating, and deletion.
export default function CornellToolsUserList(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const context = useContext(Context);


  async function resetPassword(email) {
    const user = { userEmail: `${email}` };
    setLoading(true);
    const [response, responseBody] = await apiRequest(
      `${context.isProd ? PROD_API : DEV_API}/user/${email}/resetpassword`,
      "POST",
      user
    );
    setLoading(false);
    if (response.ok) {
      props.onChangeToastMessage(responseBody.response[0].message);
      props.onChangeToastTitle("Password Reset");
    } else {
      if (response.status >= 500) {
        setErrorMessage("Internal server error. Unable to reset user password.");
        setLoading(false);
      } else {
        setErrorMessage(responseBody.error);
        setLoading(false);
      }
    }
  }


  return (
    <div className="user-list">
      <Spinner loading={loading} />
      <div className="list-header p-2"
        style={context.isProd ? { background: "var(--prod-background)" } : { background: "var(--dev-background)" }}
      >
        <div className="row align-items-top">
          <div className="list-title col-5">
            <span className="align-top">Users</span>
          </div>

          <div className="form-check form-switch mx-3 mt-1 col-4">
            {props.isConfigurator ? (
              <label className="control-label " htmlFor="toggleApplication"><strong>Application: Configurator</strong></label>
            ) : (
              <label className="control-label" htmlFor="toggleApplication"><strong>Application: Leadtime</strong></label>
            )}
            <input
              className="form-check-input"
              type="checkbox"
              id="toggleApplication"
              checked={props.isConfigurator}
              onChange={(event) => props.onChangeIsConfigurator(event.target.checked)}
            >
            </input>
          </div>

          {/* Button for creating new users. */}
          <div className="col-2">
            <button
              className="create-btn btn btn-light float-end"
              onClick={() => props.onSelect("")}
            >
              <i className="fa fa-fw fa-plus fa-1x" />
            </button>
          </div>
        </div>
      </div>

      <div className="list-body text-center">
        <div className="table-responsive">
          <table className="table table-hover mb-0" >
            <thead>
              <tr>
                <th scope="col" >User Email</th>
                <th scope="col" >User Role</th>
                <th scope="col" >Reset Password?</th>
              </tr>
            </thead>
            <tbody >
              {/* List of interactive users. */}
              {!props.loading && props.isConfigurator ?
                props.users.map((user, i) =>
                  <tr
                    key={i}
                    style={{ cursor: "pointer" }}
                  >
                    <td onClick={() => props.onSelect(user.email)}>{user.email}</td>
                    <td onClick={() => props.onSelect(user.email)}>{user.role}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-warning"
                        onClick={() => resetPassword(user.email)}>
                        Reset Password
                      </button>
                    </td>
                  </tr>
                ) : props.users.map((user, i) =>
                  <tr
                    key={i}
                    style={{ cursor: "pointer" }}
                  >
                    <td onClick={() => props.onSelect(user.email)}>{user.email}</td>
                    <td onClick={() => props.onSelect(user.email)}>{user.role}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-warning"
                        onClick={() => resetPassword(user.email)}>
                        Reset Password
                      </button>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          {props.isConfigurator ? props.users.length === 0 && !props.loading && (
            <div className="empty-list py-5 px-4">
              {errorMessage}There are no users to display
            </div>
          ) : props.users.length === 0 && !props.loading && (
            <div className="empty-list py-5 px-4">
              {errorMessage}There are no users to display
            </div>
          )}
        </div>
      </div>

    </div >
  );
}

CornellToolsUserList.propTypes = {
  loading: PropTypes.bool,
  users: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  isConfigurator: PropTypes.bool.isRequired,
  onChangeIsConfigurator: PropTypes.func.isRequired,
  onChangeToastMessage: PropTypes.func.isRequired,
  onChangeToastTitle: PropTypes.func.isRequired,
};