/*
Author:      Dimitra Weinstein
Created:     12/21/2022
Modified:    12/27/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import styles from "./PulseCountTable.module.scss";


export default function PulseCountTable(props) {
  return (
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr>
          <th>
            <span>Pulse Count</span>
          </th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        <tr>
          <td className={styles.td}>
            <span>{props.pulseCount} Pulses</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

PulseCountTable.propTypes = {
  pulseCount: PropTypes.string.isRequired
};
