/*
Author:      Dimitra Weinstein
Created:     12/27/2022
Modified:    12/27/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

export default function celsiusToFahrenheit(celsiusTemp) {
  const fahrenheit = celsiusTemp * 1.8 + 32;
  return Math.round(fahrenheit * 10) / 10;
}
