/*
Author:      Dimitra Weinstein
Created:     11/14/2022
Modified:    11/15/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

const cornellToolsAdministrativeConsoleSubdirectory = "cornell-tools-admin-console";

// Constants that are referenced throughout the Console.
module.exports = Object.freeze({
  DEV_API: "https://oe7lvb0wc8.execute-api.us-west-2.amazonaws.com/dev",
  PROD_API: "https://oe7lvb0wc8.execute-api.us-west-2.amazonaws.com/dev",
  LEADTIME: "fulfillmentLeadtime",
  CONFIGURATOR: "configurator",
  DASHBOARD: "dashboard",
  SUBDIRECTORY: cornellToolsAdministrativeConsoleSubdirectory,
  LINKS: [
    { name: "Manage Users", url: `/${cornellToolsAdministrativeConsoleSubdirectory}` }
  ],
  OPERATIONS_LINKS: [],
  ALERT_EMERGENCY: "emergency",
  ALERT_HIGH: "high",
  ALERT_MEDIUM: "medium",
  ALERT_LOW: "low",
  ALERT_INFO: "info",
  ROLES: [
    { code: "USER", name: "User" },
    { code: "OPERATIONS", name: "Operations" },
    { code: "ADMIN", name: "Admin" }
  ],
  APPLICATIONS: [
    { code: "Fulfillment Leadtime", name: "fulfillmentLeadtime" },
    { code: "Configurator", name: "configurator" }
  ],
  MIN_ROLE_LENGTH: 3,
  MAX_ROLE_LENGTH: 100,
  MIN_USER_EMAIL_LENGTH: 10,
  MAX_USER_EMAIL_LENGTH: 100
});

