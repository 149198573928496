/*
Author:      Dimitra Weinstein
Created:     12/27/2022
Modified:    12/27/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import styles from "./MapToolTip.module.scss";

export default function MapToolTip(props) {
  return (
    <div className={styles.tooltip}>
      {props.children}
    </div>
  );
}

MapToolTip.propTypes = {
  children: PropTypes.any
};
