/*
Author:      Dimitra Weinstein
Created:     11/14/2022
Modified:    11/29/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState, useContext, useReducer } from "react";
import {
  DEV_API,
  PROD_API,
  LINKS
} from "../../utilities/constantsCornellToolsAdministrativeConsole";
import CornellToolsUserList from "./UserList/CornellToolsUserList";
import CornellToolsUserModal from "./UserModal/CornellToolsUserModal";
import Context from "../../components/Context/Context";
import Error from "../../components/Error/Error";
import Spinner from "../../components/Spinner/Spinner";
import Toast from "../../components/Toast/Toast";
import deepCopy from "../../utilities/deepCopy";
import useApi from "../../hooks/useApi";
import "./CornellToolsAdministrativeConsolePage.scss";

export default function CornellToolsAdministrativeConsolePage() {
  const types = {
    SET_USER: "SET_USER",
    CREATE_USER: "CREATE_USER",
    UPDATE_USER: "UPDATE_USER",
    DELETE_USER: "DELETE_USER"
  };

  const initialUser = {
    email: "",
    role: "USER",
    application: "",
    emailVerified: true,
  };

  const [failedToLoad, setFailedToLoad] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("create");
  const [isConfigurator, setIsConfigurator] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedUser, setSelectedUser] = useState(initialUser);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [toastTitle, setToastTitle] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [users, dispatch] = useReducer(userReducer, []);
  const context = useContext(Context);


  useEffect(() => {
    setLoading(true);
    setFailedToLoad(false);
    context.setTitle("Cornell Tools Administrative Console");
    context.setLinks(LINKS);
    setLoading(false);
  }, []);


  useApi(
    () => {
      setLoading(true);
      return true;
    }, {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/user`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        isConfigurator ? dispatch({ type: types.SET_USER, payload: responseBody.applications[0].users }) :
          dispatch({ type: types.SET_USER, payload: responseBody.applications[1].users });
      } else {
        if (response.status >= 500) {
          setFailedToLoad(true);
          setErrorMessage("Internal Server Error. Unable to Load Users.");
          setLoading(false);
        } else {
          setErrorMessage(responseBody.error);
          setLoading(false);
        }
      }
      setLoading(false);
    },
    [isConfigurator, context.isProd]
  );


  // sort users by email.
  function sortUsers(users) {
    return users.sort((a, b) => {
      if (a.email > b.email) {
        return 1;
      } else if (a.email < b.email) {
        return -1;
      } else {
        return 0;
      }
    });
  }


  // If the selected ID changes, update the selected user, and mode.
  useEffect(() => {
    const index = users.findIndex(user => user.email === selectedEmail);

    if (index === -1) {
      setSelectedUser(initialUser);
    } else {
      setSelectedUser(users[index]);
    }

    if (selectedEmail === "") {
      setMode("create");
    } else if (selectedEmail !== "") {
      setMode("edit");
    }
  }, [selectedEmail, JSON.stringify(users)]);


  // User reducer function
  function userReducer(state, action) {
    switch (action.type) {
      case types.SET_USER: {
        action.payload = sortUsers(action.payload);
        return action.payload;
      }

      case types.CREATE_USER: {
        let stateDeepCopy = deepCopy(state);
        stateDeepCopy.push(action.payload);
        stateDeepCopy = sortUsers(stateDeepCopy);
        return stateDeepCopy;
      }

      case types.UPDATE_USER: {
        let stateDeepCopy = deepCopy(state);
        const userIndex = state.findIndex(user =>
          user.email === action.payload.email
        );
        if (userIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(userIndex, 1, action.payload);
          stateDeepCopy = sortUsers(stateDeepCopy);
          return stateDeepCopy;
        }
      }

      case types.DELETE_USER: {
        const stateDeepCopy = deepCopy(state);
        const userIndex = state.findIndex(user =>
          user.email === action.payload.email
        );
        if (userIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(userIndex, 1);
          return stateDeepCopy;
        }
      }

      default: {
        return state;
      }
    }
  }

  return (
    failedToLoad ? (
      <Error message={errorMessage} />
    ) : (
      <div className="page-crud pt-5 mb-4">
        <Spinner loading={loading} />

        <CornellToolsUserList
          users={users}
          email={email}
          role={role}
          isConfigurator={isConfigurator}
          onChangeIsConfigurator={(isConfigurator) => setIsConfigurator(isConfigurator)}
          onSelect={selectedEmail => setSelectedEmail(selectedEmail)}
          onChangeToastMessage={(toastMessage) => setToastMessage(toastMessage)}
          onChangeToastTitle={(toastTitle) => setToastTitle(toastTitle)}
        />

        <CornellToolsUserModal
          mode={mode}
          isConfigurator={isConfigurator}
          showModal={selectedEmail !== null}
          selectedUser={selectedUser}
          onClose={() => setSelectedEmail(null)}
          onChangeIsConfigurator={(isConfigurator) => setIsConfigurator(isConfigurator)}
          onChangeEmail={(email) => setEmail(email)}
          onChangeRole={(role) => setRole(role)}
          onChangeToastMessage={(toastMessage) => setToastMessage(toastMessage)}
          onChangeToastTitle={(toastTitle) => setToastTitle(toastTitle)}
          onAction={(type, payload) => dispatch({ type: type, payload: payload })}
        />

        <Toast
          show={toastMessage.length > 0}
          title={toastTitle}
          message={toastMessage}
          type={"success"}
          onClose={() => setToastMessage("")}
        />
      </div >
    )
  );
}

