/*
Author:      KC Willard
Created:     4/22/2022
Modified:    12/15/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/
import React, { useState, useEffect, Fragment, useContext } from "react";
import Context from "../../../components/Context/Context";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import CornellToolsUserForm from "./UserForm/CornellToolsUserForm";
import PropTypes from "prop-types";
import apiRequest from "../../../utilities/apiRequest";
import {
  MIN_USER_EMAIL_LENGTH,
  MAX_USER_EMAIL_LENGTH,
  DEV_API,
  PROD_API,
  LEADTIME,
  CONFIGURATOR,
} from "../../../utilities/constantsCornellToolsAdministrativeConsole";
import "./CornellToolsUserModal.scss";


// Modal for creating, editing, and deleting users.
export default function CornellToolsUserModal(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [email, setEmail] = useState("");
  const [application, setApplication] = useState(LEADTIME);
  const [role, setRole] = useState("USER");
  const context = useContext(Context);


  // Update state when selected user prop changes.
  useEffect(() => {
    setEmail(props.selectedUser.email);
    setRole(props.selectedUser.role);
    setApplication(props.isConfigurator ? CONFIGURATOR : LEADTIME);
  }, [JSON.stringify(props.selectedUser)]);


  // Validate the user.
  function userIsValid() {
    if (email.length < MIN_USER_EMAIL_LENGTH || email.length > MAX_USER_EMAIL_LENGTH) {
      setErrorMessage(
        `The user name must be between ${MIN_USER_EMAIL_LENGTH}`
        + ` and ${MAX_USER_EMAIL_LENGTH} characters long.`
      );
      return false;
    } else {
      setErrorMessage("");
      return true;
    }
  }


  // Create a user.
  async function createUser(email, application, role) {
    if (userIsValid()) {
      const requestBody = {
        userName: email,
        applications: [
          {
            application: application,
            role: role
          }
        ]
      };
      setLoading(true);
      // Make API request to post the new user
      const [response, responseBody] = await apiRequest(
        `${context.isProd ? PROD_API : DEV_API}/user`,
        "POST",
        requestBody
      );
      setLoading(false);
      if (response.ok && responseBody) {
        const newUser = {
          email: email,
          role: role
        };
        props.onAction("CREATE_USER", newUser);
        props.onChangeToastMessage(context.isProd ? `${email} was successfully added to ${application} in prod environment.` : `${email} was successfully added to ${application} in dev environment.`);
        props.onChangeToastTitle("Added User");
        setEmail(newUser.email);
        setRole(newUser.role.role);
        setApplication(application);
        setErrorMessage("");
        props.onClose();
      } else {
        if (response.status >= 500) {
          setErrorMessage("Internal server error. Unable to create user.");
          setLoading(false);
        } else {
          setErrorMessage(responseBody.error);
          setLoading(false);
        }
      }
    }
  }


  // Edit a user.
  async function editUser(email) {
    if (userIsValid()) {
      setLoading(true);
      const requestBody = {
        userEmail: email,
        applications: [
          {
            application: application,
            role: role
          }
        ]
      };
      //  Make API request to update the user
      const [response, responseBody] = await apiRequest(
        `${context.isProd ? PROD_API : DEV_API}/user/${email}`,
        "PUT",
        requestBody
      );
      setLoading(false);

      if (response.ok && responseBody) {
        const updatedUser = {
          email: email,
          role: role
        };
        props.onAction("UPDATE_USER", updatedUser);
        props.onChangeToastMessage(context.isProd && props.isConfigurator ? `${responseBody.user[0].message}` : `${responseBody.user[0].message}`);
        props.onChangeToastTitle("Updated User");
        setEmail(updatedUser.email);
        setRole(updatedUser.role);
        setErrorMessage("");
        props.onClose();
      } else {
        if (response.status >= 500) {
          setErrorMessage("Internal server error. Unable to update user.");
          setLoading(false);

        } else {
          setErrorMessage(responseBody.error);
          setLoading(false);
        }
      }

    }
  }


  // Delete a user.
  async function deleteUser(email) {
    setLoading(true);

    // Make API request to delete user
    const [response, responseBody] = await apiRequest(
      `${context.isProd ? PROD_API : DEV_API}/user/${email}`,
      "DELETE",
      null
    );
    setLoading(false);
    if (response.ok && responseBody) {
      console.log(responseBody);
      props.onAction("DELETE_USER", { email: email });
      props.onChangeToastMessage(context.isProd && props.isConfigurator ? `${responseBody.message}` : `${responseBody.message}`);
      props.onChangeToastTitle("Deleted User");
      setErrorMessage("");
      props.onClose();
      setShowConfirmDelete(false);
    } else {
      if (response.status >= 500) {
        setErrorMessage("Internal server error. Unable to delete user.");
        setLoading(false);

      } else {
        setErrorMessage(responseBody.error);
        setLoading(false);
      }
    }
    setShowConfirmDelete(false);
  }


  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal() {

    // Check to see if state is the same as props.
    if (
      email === props.selectedUser.email
      && role === props.selectedUser.role
    ) {
      // Since there have been no changes we can safely exit.
      dontSaveChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }


  // Save changes.
  function saveChanges() {
    if (props.mode === "create") {
      createUser();
      console.log(role);
    } else {
      editUser(props.selectedUser.email);
    }
    setShowConfirmExit(false);
  }


  // Exit without saving changes.
  function dontSaveChanges() {
    setShowConfirmExit(false);
    setErrorMessage("");
    props.onClose();
  }


  return (
    <div className="user-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        size="xl"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            {props.mode === "create" ? (
              <span>Create user</span>
            ) : (
              <span>Edit user</span>
            )}
          </h5>
        </ModalHeader>

        <ModalBody>
          <CornellToolsUserForm
            mode={props.mode}
            email={email}
            role={role}
            application={application}
            isConfigurator={props.isConfigurator}
            onChangeEmail={email => setEmail(email)}
            onChangeRole={role => setRole(role)}
            onChangeApplication={application => setApplication(application)}
            setErrorMessage={errorMessage => setErrorMessage(errorMessage)}
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {props.mode === "create" ? (
            <Fragment>
              <button className="btn btn-success" type="button" onClick={() => createUser(email, application, role)}>
                Create user
              </button>

              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button className="btn btn-danger me-auto" type="button" onClick={() => setShowConfirmDelete(true)}>
                Delete
              </button>

              <button className="btn btn-success" type="button" onClick={() => editUser(props.selectedUser.email)}>
                Save Changes
              </button>

              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={props.showModal && showConfirmDelete}
        title="Deletion Confirmation"
        content={"Are you sure that you want to delete this user? This action cannot be undone."}
        yesText="Delete User"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteUser(props.selectedUser.email)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => dontSaveChanges()}
      />
    </div>
  );
}

CornellToolsUserModal.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]).isRequired,
  isConfigurator: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  selectedUser: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  onChangeIsConfigurator: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  onChangeToastMessage: PropTypes.func.isRequired,
  onChangeToastTitle: PropTypes.func.isRequired,
};