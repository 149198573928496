/*
Author:      Dimitra Weinstein
Created:     12/20/2022
Modified:    12/30/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext, useEffect, useState } from "react";
import Context from "../../components/Context/Context";
import { DEV_API } from "../../utilities/constantsPulseMap";
import useApi from "../../hooks/useApi";
import Error from "../../components/Error/Error";
import Spinner from "../../components/Spinner/Spinner";
import Map from "../../components/Map/Map";
import { LINKS } from "../../utilities/constantsPulseMap";
import styles from "./PulseMapPage.module.scss";

export default function PulseMapPage() {

  const [failedToLoad, setFailedToLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pulseDevices, setPulseDevices] = useState([]);
  const [pulseCount, setPulseCount] = useState("");
  const context = useContext(Context);


  useEffect(() => {
    setLoading(true);
    setFailedToLoad(false);
    setErrorMessage("");
    context.setTitle("Pulse Map Console");
    context.setLinks(LINKS);
    setLoading(false);
  }, []);


  useApi(
    () => {
      setLoading(true);
      return true;
    }, {
      method: "GET",
      url: `${DEV_API}/pulse`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        setPulseDevices(responseBody.pulses);
        setPulseCount(responseBody.pulses.length.toString());
        if (response.status >= 500) {
          setFailedToLoad(true);
          setErrorMessage("Internal Server Error. Unable to Load Users.");
          setLoading(false);
        } else {
          setErrorMessage(responseBody.error);
          setLoading(false);
        }
      }
      setLoading(false);
    },
    []
  );

  return (
    failedToLoad ? (
      <Error message={errorMessage} />
    ) : (
      <div className={styles.body}>
        <Spinner loading={loading} />
        <Map
          pulseDevices={pulseDevices}
          pulseCount={pulseCount} />
      </div>
    )
  );
}