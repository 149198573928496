/*
Author:      Dimitra Weinstein
Created:     12/21/2022
Modified:    12/27/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// import PropTypes from "prop-types";
import { fitBounds } from "google-map-react";
import {
  MIN_LATITUDE,
  MAX_LATITUDE,
  MIN_LONGITUDE,
  MAX_LONGITUDE,
  GOOGLE_MAPS_DEFAULT_ZOOM,
  GOOGLE_MAPS_DEFAULT_LAT,
  GOOGLE_MAPS_DEFAULT_LNG
} from "../utilities/constantsPulseMap";


// given a set of points, calculate the center and level of zoom for a map.
export default function calculateCenterZoom(points, mapHeight, mapWidth) {
  if (points.length > 1) {
    let maxLat = MIN_LATITUDE;
    let minLat = MAX_LATITUDE;
    let maxLng = MIN_LONGITUDE;
    let minLng = MAX_LONGITUDE;

    // get the four sides of the bounding box that contains markers.
    points.forEach((point) => {
      if (point.latitude !== undefined && point.longitude !== undefined) {
        if (point.latitude > maxLat) {
          maxLat = point.latitude;
        }
        if (point.latitude < minLat) {
          minLat = point.latitude;
        }
        if (point.longitude > maxLng) {
          maxLng = point.longitude;
        }
        if (point.longitude < minLng) {
          minLng = point.longitude;
        }
      }
    });

    // If the latitude or longitude is exactly the same min and max, add a small buffer.
    if (minLat === maxLat) {
      if (minLat > MIN_LATITUDE + 0.00001) {
        minLat = minLat - 0.00001;
      } else {
        maxLat = maxLat + 0.00001;
      }
    }

    if (minLng === maxLng) {
      if (minLng > MIN_LONGITUDE + 0.00001) {
        minLng = minLng - 0.00001;
      } else {
        maxLng = maxLng + 0.00001;
      }
    }

    // Find the corners of the bound box.
    const bounds = {
      nw: {
        lat: maxLat,
        lng: minLng,
      },
      ne: {
        lat: minLat,
        lng: minLng,
      },
      sw: {
        lat: maxLat,
        lng: maxLng,
      },
      se: {
        lat: minLat,
        lng: maxLng
      }
    };

    // Get the dimensions of the current map window in pixels.
    const size = {
      width: mapWidth,
      height: mapHeight
    };

    // Find the new bounding box.
    const newBounds = fitBounds(bounds, size);

    return newBounds;
  } else if (
    points.length === 1 &&
    points[0].latitude !== undefined &&
    points[0].longitude !== undefined
  ) {
    return {
      center: { lat: points[0].latitude, lng: points[0].longitude },
      zoom: GOOGLE_MAPS_DEFAULT_ZOOM
    };
  } else {
    return {
      center: { lat: GOOGLE_MAPS_DEFAULT_LAT, lng: GOOGLE_MAPS_DEFAULT_LNG },
      zoom: GOOGLE_MAPS_DEFAULT_ZOOM
    };
  }
}
