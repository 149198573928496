/*
Author:      Dimitra Weinstein
Created:     12/21/2022
Modified:    12/30/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import formatTimeToLocal from "../../../utilities/formatTimeToLocal";
import celsiusToFahrenheit from "../../../utilities/celsiusToFahreneit";
import MapToolTip from "../MapToolTip/MapToolTip";
import styles from "./PulseMapMarker.module.scss";

export default function PulseMapMarker(props) {
  return (
    <Fragment>
      {!props.emailAddress ?
        (
          <div className={styles.body}>
            <div className={styles.wrapper}>
              {/* map marker */}
              <div className={`
          ${styles.marker} ${props.$satellite ? styles.satellite : ""} ${props.$hover ? styles.hover : ""}
          `}>
                <div className={`
            ${styles.markerDetails} ${props.$hover ? styles.hover : ""}
            `}>
                  <i className={`
              ${styles.icon} ${props.$hover ? styles.hover : ""} fa fa-fw fa-cog
              `} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.body}>
            <div className={styles.wrapper}>
              {/* map marker */}
              <div className={`
            ${styles.markerEmailAddress} ${props.$satellite ? styles.satellite : ""} ${props.$hover ? styles.hover : ""}
            `}>
                <div className={`
              ${styles.markerDetails} ${props.$hover ? styles.hover : ""}
              `}>
                  <i className={`
                ${styles.icon} ${props.$hover ? styles.hover : ""} fa fa-fw fa-cog
                `} />
                </div>
              </div>
            </div>
          </div>
        )}

      {/* pulse info that appears on hover */}
      {props.$hover && (
        <MapToolTip>
          <div className={styles.details}>
            {props.deviceIdentifier &&
              <Fragment>
                <p className={`${styles.title} my-2`}>{props.deviceIdentifier}</p>
                <p className={styles.info}>Longitude: {props.lng}</p>
                <p className={styles.info}>Latitude: {props.lat}</p>
                <p className={styles.info}>Date/Time: {formatTimeToLocal(props.requestTimeUtc)}</p>
                <p className={styles.info}>Temperature: {celsiusToFahrenheit(props.temperature)} &#8457; </p>
                <p className={styles.info}>Velocity: {props.velocity} MPH</p>
                <p className={styles.info}>Email: {props.emailAddress}</p>
              </Fragment>
            }
          </div>
        </MapToolTip>
      )}
    </Fragment>
  );
}

PulseMapMarker.propTypes = {
  text: PropTypes.string,
  deviceIdentifier: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  emailAddress: PropTypes.string,
  requestTimeUtc: PropTypes.string.isRequired,
  temperature: PropTypes.number.isRequired,
  velocity: PropTypes.number.isRequired,
  $hover: PropTypes.bool,
  $satellite: PropTypes.bool
};


